import { timestamp } from '../config/firebase'
import unique from 'uniqid'
import { storageRef } from '../config/firebase'

export const creationData = () => {
  return {
    isDelete: false,
    createdAt: timestamp,
    updatedAt: timestamp,
  }
}

export const updatationData = () => {
  return {
    updatedAt: timestamp,
  }
}

export const UploadFile = async file => {
  try {
    const directory = 'userImageId'
    const uniqueName = unique()
    const fileName = file?.name.split('.')[0]
    const fileExtension = file.name.split('.').pop()
    const fileNewName = `${uniqueName}.${fileExtension}`
    const fileDirectory = `${directory}/${fileNewName}`

    console.log('File:', file)
    console.log('New file name:', fileNewName)
    console.log('File directory:', fileDirectory)

    const fileRef = storageRef.child(fileDirectory)
    const uploadTaskSnapshot = await fileRef.put(file)
    const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL()
    return {
      success: true,
      message: '',
      profileImageName: fileNewName,
      downloadUrl,
      fileName,
      id: uniqueName,
      dir: fileDirectory,
      type: fileExtension,
    }
  } catch (error) {
    console.log('Error:', error)
    return { success: false, downloadUrl: '', message: error?.message }
  }
}
