const routeLink = {
  Door: '/door',
  Engineering: '/engineering_maintenance',
  ExtraServices: '/engineering_maintenance/extra_services',
  Hotel: '/',
  HotelWithKey: '/hotel',
  Home: '/home',
  ConfirmationScreen: '/confirmationscreen',
  LanguageScreen: '/languagescreen',
  ChangeLanguageScreen: '/changelanguagescreen',
  BookingReference: '/bookingreference',
  PreChecking: '/prechecking',
  FrontDesk: '/frontdesk',
  FoodAndBeverage: '/FoodAndBeverage',
  MyProfile: '/myprofile',
  DoctoronCall: '/doctoroncall',
  ViewBill: '/viewbill',
  Maintenance: '/maintenance',
  Replacement: '/replacement',
  HotelInfo: '/hotelInfo',
  ServiceRating: '/service-rating',
  OverallFeedback: '/overallfeedback',
  TermsandConditions: '/termsandConditions',
  PrivacyPolicy: '/privacypolicy',
  SuccessScreen: '/successscreen',
  SelectoptionTemplates: '/selectoptiontemplates',
  TimepickerTemplate: '/timepickertemplate',
  DatepickerTemplate: '/datepickertemplate',
  DateTimepickerTemplate: '/datetimepickertemplate',
  ChangeUpgradeRoom: '/ChangeUpgradeRoom',
  ExtraBed: '/ExtraBed',
  ExtendStay: '/ExtendStay',
  WakeupCall: '/WakeupCall',
  AirportDropOff: '/AirportDropOff',
  CheckoutAndRequestBill: '/CheckoutAndRequestBill',
  CheckoutAndRequestBillSuccess: '/CheckoutAndRequestBillSuccess',
  HouseKeeping: '/housekeeping',
  RoomCleaning: '/RoomCleaning',
  ClearTray: '/ClearTray',
  Chargeable: '/Chargeable',
  Complementary: '/Complementary',
  Laundry: '/Laundry',
  WrongPlace: '/wrong',
  NotAuthorized: '/not-authorized',
  RoomServiceMenu: '/roomservicemenu',
  RoomServiceCart: '/roomservicecart',
  HouseKeepingCart: '/houseKeepingCart',
  MedicalServiceCart: '/medicalservicecart',
  DoctorOnCallService: '/doctorOnCallservice',
  CuisineList: '/cuisinelist',
  RestaurantLists: '/restaurantlists',
  Reservation: '/reservation',
  Concierge: '/concierge',
  BookTaxi: '/BookTaxi',
  CarRental: '/CarRental',
  GetMyCar: '/GetMyCar',
  TravelDesk: '/TravelDesk',
  HotelShuttle: '/HotelShuttle',
  Promotions: '/promotions',
  HotelGuidelines: '/HotelGuidelines',
  PromotionDetails: '/promotiondetails',
  SpaWellness: '/spawellness',
  SpaDetails: '/spadetails',
  GymDetails: '/gymdetails',
  SaloonBooking: '/saloonbooking',
  SpaBooking: '/spabooking',
  GymBooking: '/gymbooking',
  SaloonDetails: '/saloondetails',
  Notifications: '/notifications',
  AroundMe: '/aroundme',
  Events: '/events',
  EventsList: '/eventslist',
  IntermediateScreen: '/checkout',
  OfflinePage: '/offlinePage',
  NonAuthPageNotFound: '/*',
  MaintenanceInprogress: '/maintenance_inprogress',
  MoreServices: '/moreservices',
  Chat: '/chat',
  LostAndFound: '/lostAndFound',
  FoundItems: '/foundItems',
  LostItems: '/lostItems',
}

export default routeLink
