import CryptoJS from 'crypto-js'
import { MakeNewServiceRequest } from '../services/serviceRequest'
import {
  Collections,
  secondsToShowAlert,
  SessionExpired,
  YOUR_ARE_ALREADY_CHECKEDIN_OTHER_HOTEL_RELOAD,
} from './Constants'
import moment from 'moment'
import { GuestRequest } from '../services/notification'
import { history } from '../history'
import { GetCurrentUser } from '../services/guest'
import { actions } from '../Store'
import routeLink from '../constants/routeLink'
import axios from 'axios'
import { auth, db } from './firebase'
import GuestStatus from '../constants/GuestStatus'
import { guestAppStatus } from '../constants/general'

export const SanitizeNumber = number => number.replace(/\D/g, '')

export const Sort = (array, key) => {
  const compare = (a, b) => {
    let comparison = 0
    let value1 = a[key]
    let value2 = b[key]
    if (typeof a[key] === 'string') {
      value1 = value1.toUpperCase()
      value2 = value2.toUpperCase()
    }
    if (value1 > value2) comparison = 1
    else if (value1 < value2) comparison = -1
    return comparison
  }
  return array.sort(compare)
}

export const Encrypt = text =>
  CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY).toString()

export const AddIndex = arr => {
  return arr.map((item, index) => ({
    ...item,
    srNo: index + 1,
  }))
}

export const SetAutoClearProp = (
  Func,
  value,
  clearAfterSeconds = secondsToShowAlert
) => {
  Func(value)
  setTimeout(() => Func(''), clearAfterSeconds)
}

export const SaveServiceRequest = async ({
  setShowLoader,
  setRedirect,
  requestData,
  setError,
  successCallback,
  notificationData,
  dispatch,
  isMoreRequest = false,
}) => {
  if (setShowLoader) setShowLoader(true)

  requestData.guest = notificationData.name
  requestData.roomNumber = notificationData.roomNumber
  requestData.bookingReferance = notificationData.bookingReferance
  requestData.assignedById = ''
  requestData.assignedByName = ''
  requestData.assignedToId = ''
  requestData.assignedToName = ''
  requestData.from = 'guest'
  requestData.isGuestRequest = true
  requestData.isMoreRequest = isMoreRequest

  const { success, message, requestType, referenceId, requestPath } =
    await MakeNewServiceRequest({
      ...requestData,
      completedTime: null,
      dispatch,
    })
  if (success) {
    if (successCallback) {
      successCallback()
    }

    let template_variables = {
      '%name%': notificationData.name,
      '%request%': notificationData.service,
      '%roomNumber%': notificationData.roomNumber,
      '%priority%': requestType,
    }

    let data = {
      notification_type: 'GUEST_REQUEST',
      template_variables: template_variables,
      guest_id: requestData.guestId,
      hotel_id: requestData.hotelId,
      department_type: notificationData.departmentKey,
      requestType: requestType,
      currentLanguage: notificationData.currentLanguage,
      entityType: 'REQUEST',
      referenceId: referenceId,
      service_type: notificationData.service,
      requestPath,
      departmentId: notificationData.departmentId,
    }

    GuestRequest(data)

    updateRequestStatusCount(requestData)

    updateRequestStatusCount(requestData)

    setRedirect(true)
  } else {
    SetAutoClearProp(setError, message)
  }

  if (setShowLoader) setShowLoader(false)
}

export const isValidDate = date => date instanceof Date && !isNaN(date)

export const formatTime = timePart => {
  let [hour, minute] = timePart.split(':').map(v => +v)
  let amPm = 'AM'
  if (hour > 12) {
    hour -= 12
    amPm = 'PM'
  }

  if (hour < 10) hour = '0' + hour
  if (minute < 10) minute = '0' + minute

  return `${hour}:${minute} ${amPm}`
}

export const FormatDate = (date, onlyDate) => {
  const [, month, day, year, timePart] = String(new Date(date)).split(' ')
  const formattedDate = `${day} ${month} ${year}`

  if (onlyDate) return formattedDate

  const formattedTime = formatTime(timePart)
  return `${formattedDate} - ${formattedTime}`
}

export const FormatTimeago = timestamp => {
  if (!timestamp) return ''
  let timeAgo = moment(timestamp.toDate()).fromNow(true)

  return `${timeAgo} ago`
}

export const formatPrice = value =>
  (+value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const GetHotelPhoneNumber = hotelInfo =>
  `tel:${hotelInfo?.countryCode || ''}${hotelInfo?.contactNumber}`

export const getTotalMinutes = (h, m) => h * 60 + m

export const GetRequestedTime = (date, time) => {
  if (time.length < 5) return date
  const [hour, minute] = time.split(':').map(v => +v)
  let requestedTime = new Date(date)
  requestedTime = new Date(requestedTime.setHours(hour))
  requestedTime = new Date(requestedTime.setMinutes(minute))
  return requestedTime
}

export const GetEscalationTime = (date, time) => {
  if (time.length < 5) return date
  const [hour, minute] = time.split(':').map(v => +v)
  let requestedTime = new Date(date)
  requestedTime.setHours(requestedTime.getHours() + hour)
  requestedTime.setMinutes(requestedTime.getMinutes() + minute)
  return requestedTime
}

export const GetDateTimeString = d => {
  const options = {
    hour12: true,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  if (d) {
    return new Date(d).toLocaleString('en-IN', options)
  }
  return new Date().toLocaleString('en-IN', options)
}

export const axiosError = error => {
  let statusCode = 500
  let message = error.message
  if (error.response) {
    statusCode = error.response.status
    message = error.response.data?.message
  }
  return { statusCode, message }
}

export const TimeOccursInPast = time => {
  const date = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const currentMinutes = getTotalMinutes(hours, minutes)

  const [selectedHour, selectedMinute] = time.split(':').map(v => +v)
  const selectedMinutes = getTotalMinutes(selectedHour, selectedMinute)
  return selectedMinutes <= currentMinutes
}

export const Ternary = (condition, trueValue, falseValue) =>
  condition ? trueValue : falseValue

export function onHandleClick({ e, url, dispatch }) {
  e.preventDefault()
  document.body.classList.remove('menuOpen')
  if (document.getElementById('BckBtn'))
    document.getElementById('BckBtn').classList.remove('newClass')
  const disabledDept = document.querySelectorAll('#deptClass')
  for (const element of disabledDept) {
    element.classList.remove('disableClickDept')
  }

  dispatch(
    actions.setDuplicateRequest({
      duplicateRequest: false,
      duplicateRequestServiceName: '',
    })
  )

  history.push(url)
}

const GetMomentObject = time => {
  if (!time) return moment().add(1, 'day')

  let [hours, minutes] = time.split(':')
  hours = +hours
  minutes = +minutes

  return AddTimeToMoment(hours, minutes)
}
const AddTimeToMoment = (hours, minutes) =>
  moment().set('hours', +hours).set('minutes', +minutes).set('seconds', 0)

const getServiceTimings = ({ serviceName, spa, saloon, gym }) => {
  return {
    Spa: {
      openingTime: spa?.[0]?.openingTime || null,
      closingTime: spa?.[0]?.closingTime || null,
    },
    Saloon: {
      openingTime: saloon?.[0]?.openingTime || null,
      closingTime: saloon?.[0]?.closingTime || null,
    },
    Gym: {
      openingTime: gym?.[0]?.openingTime || null,
      closingTime: gym?.[0]?.closingTime || null,
    },
  }[serviceName]
}

export const validateTime = (
  serviceName,
  spa,
  saloon,
  gym,
  translateTextI18N,
  selectedTime
) => {
  const serviceTimingData = getServiceTimings({ serviceName, spa, saloon, gym })

  let openingTime = serviceTimingData?.openingTime || null
  let closingTime = serviceTimingData?.closingTime || null

  const openingMoment = GetMomentObject(openingTime)
  const closingMoment = GetMomentObject(closingTime)
  const [hours, minutes] = selectedTime.split(':')
  const selectedMoment = AddTimeToMoment(hours, minutes)

  if (
    selectedMoment.isBefore(openingMoment) ||
    selectedMoment.isAfter(closingMoment)
  )
    return translateTextI18N('Service is not operational at the selected time')

  if (selectedMoment.isSameOrBefore(closingMoment)) {
    const minutesLeftToCloseTheRestaurant = closingMoment.diff(
      selectedMoment,
      'minutes'
    )
    if (minutesLeftToCloseTheRestaurant < 10)
      return translateTextI18N(
        'Reservation only allowed before 10 minutes of closing time'
      )
  }
}
export const openHamburgerMenu = e => {
  e?.stopPropagation()
  document.body.classList.add('menuOpen')
  if (document.getElementById('BckBtn'))
    document.getElementById('BckBtn').classList.add('newClass')
  const deptCard = document.querySelectorAll('#deptClass')
  for (const element of deptCard) {
    element.classList.add('disableClickDept')
  }
}

export const ClearLocalStorage = () => {
  document.body.classList.remove('menuOpen')

  const language = localStorage.getItem('language') || 'en'
  const keywords = localStorage.getItem('keywords') || []

  localStorage.clear()

  localStorage.setItem('language', language)
  localStorage.setItem('keywords', keywords)
}

export const toTitleCase = str => {
  if (!str) return ''
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const fetch = (obj, key, defaultValue) => {
  return obj?.[key] ? obj[key] : defaultValue
}

export const IsGuestLoggedIn = () => {
  if (localStorage.getItem('apitoken') && GetCurrentUser()) {
    window.location.reload()
    return true
  }
  return false
}

export const GuestLoggedIn = () =>
  localStorage.getItem('apitoken') && GetCurrentUser()

export function Logout({
  dispatch,
  routeLinkObj = routeLink.IntermediateScreen,
  guestId = '',
}) {
  let hotelKey = localStorage.getItem('hotel')
  if (guestId) {
    dispatch(actions.setLoggedOutGuestId(guestId))
  }
  dispatch(actions.setLogout())
  ClearLocalStorage()
  unsubscribeFirebaseListeners()
  if (hotelKey && hotelKey !== 'null') {
    localStorage.setItem('hotel', hotelKey)
  }

  history.push(routeLinkObj)
  document.body.classList.remove('menuOpen')
  dispatch(actions.setUrlLoader(false))
  dispatch(actions.setGuestFromUrl(null))
  dispatch(actions.setHotelFromUrl(null))
}

export const CheckoutGuest = async (dispatch, guestId) => {
  await auth.signOut().then(() => {
    unsubscribeFirebaseListeners()
    dispatch(
      actions.setAppStatus({
        logoutFrom: 'PrivateLayout',
        guestStatus: GuestStatus.CheckOut,
        appStatus: guestAppStatus.CheckOut,
      })
    )
    Logout({ dispatch, guestId: guestId })
  })
}

export function ClearUserData({ dispatch }) {
  let hotelKey = localStorage.getItem('hotel')
  dispatch(actions.setLogout())
  ClearLocalStorage()
  localStorage.setItem('hotel', hotelKey)
  document.body.classList.remove('menuOpen')
}

export const GetLocalValues = () => ({
  guestId: localStorage.getItem('guestId'),
  hotel: localStorage.getItem('hotel'),
  apitoken: localStorage.getItem('apitoken'),
})

export const wGetHotelKey = window.location.pathname
  .replace(`${routeLink.HotelWithKey}/`, '')
  .replace('/', '')

export const hotelKeyData = params => {
  return params?.hotelslugname && wGetHotelKey
}

export const getManifestData = dispatch =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/v1/onesignal/manifest`)
    .then(res => {
      console.log('manifest', res.data)
      dispatch(actions.setManifestFileData(res.data))
    })

export function btnCheckCrossTab({ setHotelKey, subDomain }) {
  setHotelKey(localStorage.getItem('hotel'))
  if (localStorage.getItem('hotel') !== subDomain) {
    if (localStorage.getItem('hotel')) {
      window.location.reload()
      return true
    }
  }
  return false
}

export const intervalTime = 1000

export const checkCrossTab = ({ subDomain, isPWA, props }) => {
  let interval = null
  if (!isPWA) {
    setInterval(() => {
      commonCrossTabCallback({ subDomain, props })
    }, 1000)
  }
  return () => {
    clearInterval(interval)
  }
}

export const setCurrentHotelKey = (dispatch, payload) => {
  dispatch(actions.setCurrentHotelKey(payload))
}

const options = {
  customButton: (
    <button
      className='outerlineBtn mx-1'
      onClick={e => {
        e.preventDefault()
        window.location.reload()
      }}
    >
      Reload
    </button>
  ),
}

export const commonCrossTabCallback = ({ subDomain, props }) => {
  let lsHotel = localStorage.getItem('hotel')
  let lsGuest = localStorage.getItem('guestId')
  let lsApiToken = localStorage.getItem('apitoken')

  if (lsHotel && subDomain) {
    if (lsHotel !== subDomain) {
      props.setAlrtMessage({
        msg: YOUR_ARE_ALREADY_CHECKEDIN_OTHER_HOTEL_RELOAD,
        options,
      })
      props.setHotelKey(lsHotel)
      props.setModal(true)
    } else {
      if (auth?.currentUser) {
        if (lsGuest) {
          window.location.reload(routeLink.ConfirmationScreen)
        }

        if (lsApiToken) {
          window.location.reload(routeLink.Home)
        }
      }
    }
  }

  if (!lsHotel) {
    props.setAlrtMessage({
      msg: SessionExpired,
      options,
    })
    props.setHotelKey(lsHotel)
    props.setModal(true)
  }
}

export function getRequestCollection(hotelId, departmentId) {
  return db
    .collection(Collections.REQUEST_INFO)
    .doc(hotelId)
    .collection(Collections.REQUEST_INFO_DEPARTMENT)
    .doc(departmentId)
    .collection(Collections.REQUEST_INFO_DEPARTMENT_REQUEST)
}

export function getRequestPath(hotelId, departmentId, documentId) {
  return db
    .collection(Collections.REQUEST_INFO)
    .doc(hotelId)
    .collection(Collections.REQUEST_INFO_DEPARTMENT)
    .doc(departmentId)
    .collection(Collections.REQUEST_INFO_DEPARTMENT_REQUEST)
    .doc(documentId).path
}

export function convertImageURLToBase64(url, callback) {
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    const reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.send(null)

  return xhr
}

export function getMobileOS() {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'iOS'
  }
  return 'Other'
}

export let unsubscribeList = {}

export let whiteList = []
export const unsubscribeFirebaseListeners = () => {
  Object.entries(unsubscribeList).forEach(([key, func]) => {
    if (whiteList.length && whiteList.includes(key)) return

    if (typeof func === 'function') {
      func()
    }
    unsubscribeList[key] = null
  })
}

export function isEmpty(objData) {
  return !!!Object.keys(objData || {}).length
}

export function dispatchSetLanguage(dispatch, lang) {
  dispatch(
    actions.setLanguageConfig({
      currentLanguage: lang.currentLanguage,
      previousLanguage: lang.previousLanguage,
      googleLangCode: lang.googleLangCode,
    })
  )
}

export function areObjectsEqual(obj1, obj2) {
  const entries1 = Object.entries(obj1)
  const entries2 = Object.entries(obj2)

  if (entries1.length !== entries2.length) {
    return false
  }

  for (const [key, value] of entries1) {
    if (obj2[key] !== value) {
      return false
    }
  }

  return true
}

export const calcAvgRatingFunc = feedbackQuestionRating => {
  let ratinData = Object.values(feedbackQuestionRating || {})
  if (ratinData.length === 0) return 0

  const answeredQuestions = ratinData.filter(r => r)
  const sum = answeredQuestions.reduce(
    (accumulator, rating) => accumulator + +rating,
    0
  )
  const averageRating = sum / answeredQuestions.length

  let overallRating = 0

  if (averageRating > 0) {
    const decimalPart = averageRating % 1

    overallRating = Ternary(
      decimalPart >= 0.5,
      Math.ceil(averageRating),
      Math.floor(averageRating)
    )
  }

  return Ternary(isNaN(overallRating), 0, overallRating)
}

export function findIframeWithAttributes(node) {
  if (
    node.tagName === 'IFRAME' &&
    node.style.backgroundColor === 'transparent' &&
    node.style.border === '0px' &&
    node.style.display === 'none'
  ) {
    return node
  }

  for (let currNode of node.children) {
    const foundNode = findIframeWithAttributes(currNode)
    if (foundNode) {
      return foundNode
    }
  }

  return null
}

export function removeIFRAMETag() {
  const body = document.querySelector('body')
  const iframeWithAttributes = findIframeWithAttributes(body)

  if (iframeWithAttributes) {
    iframeWithAttributes.parentNode.removeChild(iframeWithAttributes)
  }
}

export function containsTimeDateOrRedirect(userRequest) {
  // Patterns to match common date formats
  const datePatterns = [
    /\b(?:\d{1,2}[-/th|st|nd|rd\s\.]*\s*(Jan|January|Feb|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)?[-/th|st|nd|rd\s\.]*\d{2,4})\b/i,
    /\b(?:\d{1,2}[-/th|st|nd|rd\s\.]*\s*(Jan|January|Feb|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December))\b/i,
    /\b(?:\d{4}[-/]\d{2}[-/]\d{2})\b/i,
    /\b(?:\d{2}[-/]\d{2}[-/]\d{4})\b/i,
    /\b(?:\d{2}\/\d{2})\b/i,
    /\b(?:tomorrow)\b/i,
  ]

  // Patterns to match common time formats
  const timePatterns = [
    /\b\d{1,2}:\d{2}\s*(AM|PM|am|pm)?\b/i,
    /\b\d{1,2}\s*(AM|PM|am|pm)\b/i,
  ]

  // Combine all date and time patterns
  const combinedPatterns = datePatterns.concat(timePatterns)

  // Check if request contains any date or time
  for (let pattern of combinedPatterns) {
    if (pattern.test(userRequest)) {
      return 'true'
    }
  }

  // Check if request mentions redirecting to a page
  if (/\b(?:redirect|redirects|redirected|redirecting)\b/i.test(userRequest)) {
    return 'true'
  }

  return 'false'
}

export const product_analytics = async analytics_data => {
  try {
    const data = analytics_data
    await db.collection('ai_analytics').add(data)
  } catch (e) {
    console.error('Error adding document: ', e)
  }
}

// Function to get count for a specific status
const getQuerySnapshot = async query => {
  const snapshot = await query.get()
  return snapshot.size
}

const createQuery = (hotelId, departmentId, options = {}) => {
  let query = db
    .collection(Collections.REQUEST_INFO)
    .doc(hotelId)
    .collection(Collections.REQUEST_INFO_DEPARTMENT)
    .doc(departmentId)
    .collection(Collections.REQUEST_INFO_DEPARTMENT_REQUEST)
    .where('isDelete', '==', false)

  if (options.status) query = query.where('status', '==', options.status)
  if (options.isGuestRequest !== undefined)
    query = query.where('isGuestRequest', '==', options.isGuestRequest)
  if (options.serviceId)
    query = query.where('serviceId', '==', options.serviceId)
  if (options.assignedById)
    query = query.where('assignedById', '==', options.assignedById)

  return query
}

const getCount = async (hotelId, departmentId, options = {}) => {
  try {
    const query = createQuery(hotelId, departmentId, options)
    console.log(query)
    return await getQuerySnapshot(query)
  } catch (error) {
    console.error('Error getting count:', error)
    throw error
  }
}

const updateBatchWithServiceData = (batch, ref, serviceData) => {
  batch.set(ref, serviceData, { merge: true })
}

const createServiceData = (count, serviceId, service, serviceKey) => ({
  count,
  id: serviceId,
  isSubService: false,
  name: service,
  serviceKey,
  subServiceName: '',
  subServiceid: '',
})

export const updateRequestStatusCount = async requestData => {
  try {
    const { hotelId, departmentId, serviceId, service, serviceKey } =
      requestData || {}

    const statuses = ['Pending', 'Deferred', 'Completed', 'In Progress']
    const counts = await Promise.all(
      statuses.map(status => getCount(hotelId, departmentId, { status }))
    )

    const updateData = {
      overall_request: counts.reduce((sum, count) => sum + count, 0),
      ...Object.fromEntries(
        statuses.map((status, index) => [
          `overall_${status.toLowerCase()}`,
          counts[index],
        ])
      ),
    }

    const batch = db.batch()
    const dashboardRef = db
      .collection(Collections.MOBILE_DASHBOARD)
      .doc(hotelId)

    const overallPendingCountUpdate = dashboardRef
      .collection(Collections.DEPARTMENT_REQUEST_STAT)
      .doc(departmentId)
    batch.set(overallPendingCountUpdate, updateData, { merge: true })

    const [
      pendingDepartmentRequestCount,
      pendingDepartmentRequestCountByServiceId,
      pendingGuestRequestCountByServiceId,
    ] = await Promise.all([
      getCount(hotelId, departmentId, {
        status: 'Pending',
        isGuestRequest: false,
      }),
      getCount(hotelId, departmentId, { status: 'Pending', serviceId }),
      getCount(hotelId, departmentId, {
        status: 'Pending',
        isGuestRequest: true,
        serviceId,
      }),
    ])

    const departmentStatRef = dashboardRef
      .collection(Collections.DEPARTMENT_REQUEST_STAT)
      .doc(departmentId)

    batch.set(
      departmentStatRef,
      {
        department_pending: pendingDepartmentRequestCount,
      },
      { merge: true }
    )

    const serviceData = createServiceData(
      pendingDepartmentRequestCountByServiceId,
      serviceId,
      service,
      serviceKey
    )
    updateBatchWithServiceData(
      batch,
      departmentStatRef.collection(Collections.GUEST_REQUEST).doc(serviceId),
      { ...serviceData, count: pendingGuestRequestCountByServiceId }
    )

    await batch.commit()
    console.log('Dashboard updated successfully')
  } catch (error) {
    console.error('Error updating dashboard:', error)
    throw error
  }
}
